<template>
  <BirthdayTable
    :data="data"
    :page="page"
    :pageSize="pageSize"
    :contacts="thisMonth"
    :total="total"
    :handleCurrentChange="handleCurrentChange"/>
</template>

<script>
import BirthdayTable from './BirthdatTable'
export default {
    name:'this_month',
    components: {BirthdayTable},
     props: {
        thisMonth: {
            required: true,
            type: Array
        },
        page: {
            required: true,
            type: Number
        },
        pageSize:{
            required: true,
            type: Number
        },
        total: {
            required: true,
            type: Number
        },
        handleCurrentChange:{
            type: Function,
            required: true
        }
    },
    data() {
        return {
            data: {
                message: "No Birthday contacts this                                                                                                                                                                                                      month.",
                contacts: this.thisMonth
            }
        }
    }
}
</script>

<style>

</style>